@import "~antd/dist/antd.css";

.ant-descriptions-item-content {
  flex-wrap: wrap;
}

.site-layout {
  background: #fff;
}

.info-field {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.position-progress-bar {
  margin-bottom: 40px;
}

.back-btn {
  position: relative;
  top: -10px;
}

blockquote {
  margin: 0 0 0 2em;
}

/* detail page */
.detail-header {
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f1f1f1;
}
.detail-header__text {
  padding-left: 20px;
}
.detail-header__text > div:first-child {
  font-size: 20px;
  font-weight: bold;
}
.detail-header > .detail-header-action {
  flex-grow: 1;
  text-align: right;
}

/* match detail page */
.match-header {
  justify-content: space-between;
  padding: 0;
}
.match-header > div {
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
}
.match-header > div:nth-child(1),
.match-header > div:nth-child(3) {
  padding: 10px;
}
.match-header .divider:before {
  content: "";
  border-right: 2px solid #aabec7;
  height: 120px;
  position: relative;
  left: 14px;
}
.match-header .match-action {
  background-color: #f9f9f9;
  width: 200px;
  height: 120px;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #dedede;
}
/* match detail -End */

.ant-descriptions-item-label {
  font-weight: 300;
}

.recruiterMenu .ant-menu-submenu-arrow {
  display: none;
}

.detail-tab-list > .ant-tabs-nav {
  box-shadow: 0 0 19px 0px rgb(218 218 218 / 75%);
}

/* position detail => matches tab */
.action-wrap {
  display: flex;
  align-items: center;
  height: 34px;
  background-color: #f0f6fb;
  margin-bottom: 10px;
}

.action-wrap > .action-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 410px;
}

.ql-content-area {
  border-radius: 5px;
  padding: 15px;
  background-color: #eaecec;
}

/* match detail Table - start */

.comparison {
  margin: 50px 0;
}
.comparison table,
.comparison th,
.comparison td {
  border: 1px solid #a0a0a0;
  padding: 10px;
}

.comparison table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  background: #fafafa;
  width: 100%;
}
.comparison th,
.comparison td {
  text-align: center;
}
.comparison td:first-child {
  text-align: left;
}
.comparison th:nth-child(1) {
  width: 200px;
}
.comparison th:nth-child(2) {
  width: 400px;
}
.comparison th:nth-child(3) {
  width: 400px;
}
.comparison th:nth-child(4) {
  width: 40px;
}

.comparison thead tr {
  background-color: #acb9c3;
}
.comparison tr:nth-child(even) {
  background-color: #eaeaea;
}

.score-red {
  background-color: #ff9898;
}
.score-green {
  background-color: #abe6c1;
}
.score-yellow {
  background-color: #fbdd80;
}

/* match detail Table - End */

/* Rich Text Editor - Start */

.rich-text .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.rich-text .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.rich-text .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.rich-text .ql-editor {
  min-height: 18em;
}

.login-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #64b7dc;
  border-color: #64b7dc;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #64b7dc;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.interview-editor .DraftEditor-root {
  min-height: 50vh;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

div.ant-select-dropdown {
  z-index: 99999999 !important;
}

div.DraftEditor-root {
  position: static;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  position: static;
}

.DraftEditor-editorContainer h3,
.public-DraftEditor-content h3,
.DraftEditor-editorContainer h4,
.public-DraftEditor-content h4 {
  font-weight: 600;
}
